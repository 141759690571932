import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';

import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Router } from '@angular/router';
import {
  APPEND_TOKEN,
  IDAM_TOKEN,
  JCN_TOKEN,
  REDIRECT_ON_UNAUTHORIZE,
} from 'src/app/constants';
import { AuthService } from './../services/auth.service';
import { NativeBridgeService } from './native-bridge.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private nativeBridgeService: NativeBridgeService
  ) {}
  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.authService.getAuthToken()) {
      const isAppendToken = request.context.get(APPEND_TOKEN);
      if (isAppendToken) {
        request = this.addToken(request, this.authService.getAuthToken());
      }
    }
    if (this.authService.getJcnToken()) {
      const isAppendJcnToken = request.context.get(JCN_TOKEN);
      if (isAppendJcnToken) {
        request = this.addToken(request, this.authService.getJcnToken());
      }
    }
    if (this.authService.getIDAMAuthToken()) {
      const isAppendAccessToken = request.context.get(IDAM_TOKEN);
      if (isAppendAccessToken) {
        request = this.addToken(request, this.authService.getIDAMAuthToken());
      }
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (request.context.get(REDIRECT_ON_UNAUTHORIZE)) {
            this.nativeBridgeService.tokenExpired();
            this.authService.logout();
            window.location.href = '';
            void this.router.navigate(['']);
          }
        }
        return throwError(error);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        AUTHORIZATION: `Bearer ${token}`,
      },
    });
  }
}
