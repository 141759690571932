import { HttpContextToken } from '@angular/common/http';

export enum ServerErros {
  UNSUPPORTED_IDENTITY_TYPE = 'UNSUPPORTED_IDENTITY_TYPE',
  INVALID_TOKEN = 'INVALID_TOKEN',
  MISMATCHED_IDENTITIES = 'MISMATCHED_IDENTITIES',
  MISMATCHED_IDENTITY_VALUE = ' MISMATCHED_IDENTITY_VALUE',
  ACCOUNT_BLOCKED = 'ACCOUNT_BLOCKED',
  IDENTITY_UPDATE_FAILED = 'IDENTITY_UPDATE_FAILED',
  MEMBER_ALREADY_EXISTS = 'MEMBER_ALREADY_EXISTS',
  IDENTITY_NOT_FOUND = 'IDENTITY_NOT_FOUND',
  MISSING_ACCOUNT_ID = 'MISSING_ACCOUNT_ID',
  TRY_AGAIN = 'TRY_AGAIN',
  INVALID_OTP = 'INVALID_OTP',
  OTP_VERIFICATION_FAILED = 'OTP_VERIFICATION_FAILED',
  MAX_CAP_REACHED = 'MAX_CAP_REACHED',
  UNAUTHORIZED = 'Unauthorized',
  PHONE_NUMBER_NOT_ENROLLED = 'PHONE_NUMBER_NOT_ENROLLED',
}

export const APPEND_TOKEN = new HttpContextToken<boolean>(() => true);
export const JCN_TOKEN = new HttpContextToken<boolean>(() => false);
export const IDAM_TOKEN = new HttpContextToken<boolean>(() => false);
export const MONTHLY_TOKEN = new HttpContextToken<boolean>(() => false);
export const REDIRECT_ON_UNAUTHORIZE = new HttpContextToken<boolean>(
  () => true
);

export enum PLATFORM {
  NATIVE_APP = 'native',
  WEB_APP = 'web',
}
