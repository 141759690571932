import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../services/profile.service';
import { AuthService } from '../services/auth.service';
import { NativeBridgeService } from '../services/native-bridge.service';
import { UtilService } from '../services/util.service';

interface OTPSuccessServerResponse {
  success: boolean;
  identityId: string;
  isNewUser: boolean;
  accessToken: string;
  loginFailedReason: string;
  idamAccessToken: string;
}

@Injectable()
export class AuthGuard {
  isLoggedIn = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private profileService: ProfileService,
    private http: HttpClient,
    private nativeBridgeService: NativeBridgeService,
    private utilService: UtilService,
    private route: ActivatedRoute
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isLoggedIn && !state.url.includes('/email-verification')) {
      return true;
    }

    const jwtFromRoute = this.utilService.getParameterByName('token');
    if (jwtFromRoute && jwtFromRoute !== 'null') {
      return this.validateJWT(jwtFromRoute);
    }

    if (state.url.includes('auth_code')) {
      if (this.authService.getIsAuthenticated()) {
        return true;
      }
      const token = this.utilService.getParameterByName('auth_code');
      if (token) {
        try {
          return this.verifyToken(token);
        } catch (error) {
          return this.router.navigate(['']);
        }
      } else {
        return this.router.navigate(['']);
      }
    }

    if (state.url == '/' && !this.authService.getIsAuthenticated()) {
      return true;
    } else if (!this.authService.getIsAuthenticated()) {
      if (state.url.includes('/email-verification')) {
        this.authService.redirectURL = window.location.pathname;
        localStorage['newToken'] = window.location.hash.slice(1);
        return this.router.navigate(['/sign-in']);
      } else {
        return this.router.navigate(['/']);
      }
    } else if (this.authService.getIsAuthenticated()) {
      if (state.url.includes('/email-verification')) {
        this.authService.redirectURL = window.location.pathname;
        if (!localStorage['newToken']) {
          localStorage['newToken'] = window.location.hash.slice(1);
        }
        return true;
      } else if (!this.profileService.getProfileSync()) {
        return this.profileService.getProfileInfo().subscribe(() => {
          this.authService.isLoggedIn$.next(true);
          return true;
        });
      }
      this.authService.isLoggedIn$.next(true);
      return true;
    } else {
      return this.router.navigate(['']);
    }
  }

  private verifyToken(token: string) {
    return new Observable<boolean>((observer) => {
      this.http
        .post<OTPSuccessServerResponse>(
          environment.apiHost + '/auth/retail-idam/verify',
          {
            authCode: token,
            clientId: String(environment.clientIdForRetailIDAM),
          }
        )
        .subscribe(
          (res: OTPSuccessServerResponse) => {
            this.authService.setIDAMAuthToken(res.idamAccessToken);
            this.authService.setAuthToken(res.accessToken);
            this.authService.setAuthInfo({
              jwt: res['accessToken'],
              info: this.utilService.parseJWT(res['accessToken']),
            });
            observer.next(true);
            observer.complete();
          },
          () => {
            this.isLoggedIn = false;
            observer.next(false);
            observer.complete();
            this.authService.logout();
            this.router.navigate(['']).catch(() => {});
          }
        );
    });
  }

  public validateJWT(jwt: string) {
    return new Observable<boolean>((observer) => {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', `Bearer ${jwt}`);
      this.http
        .get(environment.apiHost + '/api/user/profile', {
          headers: headers,
        })
        .subscribe(
          () => {
            try {
              const parsed = this.utilService.parseJWT(jwt);
              this.isLoggedIn = true;
              this.authService.setAuthInfo({
                jwt: jwt,
                info: parsed,
              });
              observer.next(true);
              observer.complete();
            } catch (error) {
              console.error('error');
              window.location.href = '';
              this.authService.logout();
              this.router.navigate(['/']).catch(() => {});
            }
          },
          () => {
            this.isLoggedIn = false;
            observer.next(false);
            observer.complete();
            this.authService.logout();
          }
        );
    });
  }
}
