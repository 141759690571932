import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IDAM_TOKEN, REDIRECT_ON_UNAUTHORIZE } from 'src/app/constants';
import { UtilService } from './util.service';
import { RetailIDAMResponse } from '../interfaces/idam-auth.interfaces';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface AuthInfo {
  jwt?: string;
  [key: string]: any;
}

interface JcnInfo {
  jwt?: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authInfo: AuthInfo = {};
  private jcnInfo: JcnInfo = {};
  public isLoggedIn$ = new BehaviorSubject<boolean>(false);
  public redirectURL?: string;

  constructor(private util: UtilService, private http: HttpClient) {}

  setAuthInfo(info: AuthInfo) {
    this.authInfo = info;
    localStorage['authInfo'] = JSON.stringify(info);
  }

  setJcnInfo(info: JcnInfo) {
    this.jcnInfo = info;
    localStorage['jcnInfo'] = JSON.stringify(info);
  }

  getAuthInfo(): AuthInfo {
    const authInfo = localStorage['authInfo'] as string;
    if (authInfo) {
      return JSON.parse(authInfo) as AuthInfo;
    }
    return this.authInfo;
  }
  getJcnInfo(): JcnInfo {
    const jcnInfo = localStorage['jcnInfo'] as string;
    if (jcnInfo) {
      return JSON.parse(jcnInfo) as JcnInfo;
    }
    return this.jcnInfo;
  }

  getIsAuthenticated(): boolean {
    const authInfo = this.getAuthInfo();
    return !!authInfo.jwt;
  }

  getJcnAuthenticated(): boolean {
    const jcnInfo = this.getJcnInfo();
    return !!jcnInfo.jwt;
  }

  setAuthToken(token: string) {
    this.authInfo.jwt = token;
  }

  setJcnToken(token: string) {
    this.jcnInfo.jwt = token;
  }

  getAuthToken(): string {
    const authInfo = this.getAuthInfo();
    return authInfo.jwt ? authInfo.jwt : '';
  }

  getJcnToken(): string {
    const jcnInfo = this.getJcnInfo();
    return jcnInfo.jwt ? jcnInfo.jwt : '';
  }

  logout() {
    this.isLoggedIn$.next(false);
    this.setAuthInfo({});
    this.setJcnInfo({});
    this.clearIDAMAuthToken();
    this.clearMonthlyReportToken();
  }

  setIDAMAuthToken(token: string): void {
    localStorage.setItem('idamAccessToken', token);
  }

  getIDAMAuthToken(): string {
    return localStorage.getItem('idamAccessToken') || '';
  }

  clearIDAMAuthToken(): void {
    localStorage.removeItem('idamAccessToken');
  }

  setMonthlyReportToken(token: string) {
    localStorage.setItem('monthlyReportToken', token);
  }
  getMonthlyReportToken(): string {
    return localStorage.getItem('monthlyReportToken') as string;
  }
  clearMonthlyReportToken() {
    localStorage.removeItem('monthlyReportToken');
  }
  validateIDAMSession(
    clientId: string,
    idamAccessToken: string
  ): Observable<{ success: boolean }> {
    if (
      !environment.retailIDAMBackendURL ||
      !environment.clientIdForRetailIDAM
    ) {
      console.log(
        'Please check the configuration and try again. IDAM not enabled'
      );
      return of({ success: false });
    }

    const url = `${environment.retailIDAMBackendURL}/service/application/retail-auth/v2.0/validate-token`;

    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: clientId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${idamAccessToken}`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    });

    return this.http
      .post<RetailIDAMResponse>(
        url,
        {},
        {
          headers,
          context: new HttpContext()
            .set(IDAM_TOKEN, true)
            .set(REDIRECT_ON_UNAUTHORIZE, false),
        }
      )
      .pipe(
        map(() => {
          return { success: true };
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            console.error(
              'Unauthorized - Invalid credentials or authorization issue',
              err.error
            );
          } else if (err.status === 400) {
            console.error(
              'Bad Request - Possibly invalid parameters',
              err.error
            );
          }
          return of({ success: false });
        })
      );
  }

  validateSession(): Observable<boolean> {
    const clientId = environment.clientIdForRetailIDAM;
    if (!environment.retailIDAMBackendURL) {
      return of(true);
    }

    if (!clientId) {
      return of(true);
    }

    const idamAccessToken = this.getIDAMAuthToken();
    if (!idamAccessToken) {
      this.isLoggedIn$.next(false);
      return of(true);
    }

    return this.validateIDAMSession(clientId, idamAccessToken).pipe(
      map((response) => {
        const isValid = response.success;
        if (!isValid) {
          this.clearIDAMAuthToken();
          this.isLoggedIn$.next(false);
        }

        return isValid;
      }),
      catchError(() => {
        this.clearIDAMAuthToken();
        this.isLoggedIn$.next(false);
        return of(false);
      })
    );
  }
}
